<template>
  <div class="query-header">
    <query :handle-query="handleQuery" :select-options="selectOptions" :show-select="showSelect" :title="title" :query="query" :placeholder="placeholder"/>
    <div class="op">
      <a-button ghost type="primary" @click="handleClickAddBtn" v-if="showAddBtn" icon="plus">{{ addBtnText }}</a-button>
      <slot></slot>
      <a-button class="refresh" @click="handleRefresh">刷新</a-button>
    </div>
  </div>
</template>

<script>
import Query from './Query.vue';

export default {
  name: 'QueryHeader',
  components: { Query },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    query: {
      type: Object
    },
    showSelect: {
      type: Boolean,
      default: true
    },
    showAddBtn: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '类型'
    },
    selectOptions: Array,
    addBtnText: String,
    handleQuery: Function,
    handleRefresh: Function,
    handleClickAddBtn: Function
  }
};
</script>

<style lang="less" scoped>
.query-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  .op {
    & > button + button {
      margin-left: 10px;
    }
  }
}
</style>
